import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import { GatsbyImage } from 'gatsby-plugin-image';
import TwHero from '../../components/TwHero';
import HeroServices from '../../components/HeroServices';
const Areas = (props) => {
  const areas = props.data.allMdx.edges;
  const heroBackground = props.data.heroBackground;
  return (
    <Layout bodyClass="page-areas">
      <SEO title="Areas" />
      <TwHero
        bg={
          '/2022/images/best-home-ac-air-conditioning-service-repair-minnesota.jpg'
        }
        headline="Service Area For Minnesota Residential Furnace / Air Conditioning (AC) Service, Installation, and Repair"
        text="Nearby to a service area and in need of furnace or air conditioning service/repair/installation?"
        btnURL="/hvac-service-installation-repair-scheduling"
        btnTxt="Schedule Home HVAC Service"
        featured={[
          {
            name: 'HVAC Housecall',
            color: 'red-800',
            href: '/hvac-service-installation-repair-scheduling',
            description: `HVAC units don't sleep! We offer emergency 24/7 emergency repair, inspection, and installation. If your AC or Furnace is doing anything out of the ordinary, let us know!`,
            icon: '/2022/icons/minnesota-hvac-service-repair-installation.svg',
            btnTxt: 'Get HVAC Support',
          },
          {
            name: 'Furnace Installation & Repairs',
            href: '/hvac-service-installation-repair-scheduling',
            color: 'orange-500',
            description: `Need a new furnace installation? We've got you covered. As a trusted Bryant® HVAC installation team, we've got you covered with the best HVAC units we've found to date. `,
            icon: '/2022/icons/minnesota-furnace-hvac-service-installation-repair.svg',
            btnTxt: 'Schedule Furnace or Heater Service',
          },
          {
            name: 'AC Installation, Service, Repair',
            href: '/hvac-service-installation-repair-scheduling',
            color: 'blue-500',
            description: `Our 3-month window of NEEDING a functioning air conditioning unit is upon us. Coincidentally this is also when they typically decide to die. If you either don't have one or have one on the fritz, get in touch. We can help with AC installations, inspections, and repairs.`,
            icon: '/2022/icons/best-ac-air-conditioning-service-repair-minnesota.svg',
            btnTxt: 'Schedule AC Service or Installation',
          },
        ]}
      />
      <HeroServices
        className="HeroServices"
        title="LaSalle Commercial & Residential HVAC Service Area"
        subTitle="We offer quality furnace and heating services for South Metro, Twin Cities homes and businesses."
      />

      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          {areas.map((edge, i) => (
            <div
              key={`${i}-${edge.node.frontmatter.path}`}
              className="col-span-1 flex justify-center py-8 px-8 bg-gray-50"
            >
              <Link to={edge.node.frontmatter.path}>
                <div className="grid items-center grid-rows-3 grid-cols-1 justify-between h-full">
                  {edge.node.frontmatter.image && (
                    <div className="grid items-center grid-cols-1 row-span-2">
                      <img
                        alt={edge.node.frontmatter.title}
                        className="mix-blend-multiply"
                        src={edge.node.frontmatter.image}
                      />
                    </div>
                  )}
                  <div className="row-span-1 row-start-3 ">
                    <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                      {edge.node.frontmatter.title}
                    </h2>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AreasQuery {
    allMdx(
      filter: { fileAbsolutePath: { regex: "../../mdx/areas/" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          body
          frontmatter {
            title
            path
            image
          }
        }
      }
    }
    heroBackground: file(
      relativePath: {
        eq: "ac/air-conditioning-ac-service-repair-minnesota.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Areas;
